import { Check } from 'lucide-react';

import { cn } from '@/lib/utils';

import { IconButton, type IIconButtonProps } from '../icon-button';
import { Badge } from '../ui/badge';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../ui/card';

export interface IPricingCardProps {
  className?: string;
  title: string;
  popular: boolean;
  price: number;
  description: string;
  buttonText: string;
  benefitList: string[];
  hideButton?: boolean;
  buttonProps?: Omit<IIconButtonProps, 'onClick'>;
  onButtonClick?: () => void;
}

export const PricingCard = ({
  className,
  title,
  popular,
  price,
  description,
  buttonText,
  benefitList,
  buttonProps,
  onButtonClick,
}: IPricingCardProps) => {
  return (
    <Card
      key={title}
      className={cn(
        popular ? 'shadow-black/10 drop-shadow-xl dark:shadow-white/10' : '',
        className,
      )}
    >
      <CardHeader>
        <CardTitle className="flex items-center justify-between">
          {title}
          {popular ? (
            <Badge variant="secondary" className="text-sm text-primary">
              Most popular
            </Badge>
          ) : null}
        </CardTitle>
        <div>
          {/* <span className="text-lg">US </span> */}
          <span className="text-4xl font-bold">${price}</span>
          <span className="text-muted-foreground"> per month</span>
        </div>

        <CardDescription>{description}</CardDescription>
      </CardHeader>

      <CardContent>
        <IconButton className="w-full" onClick={onButtonClick} {...buttonProps}>
          {buttonText}
        </IconButton>
      </CardContent>

      <hr className="m-auto mb-4 w-4/5" />

      <CardFooter className="flex">
        <div className="space-y-4">
          {benefitList.map((benefit: string) => (
            <span key={benefit} className="flex">
              <Check className="text-green-500" />{' '}
              <h3 className="ml-2">{benefit}</h3>
            </span>
          ))}
        </div>
      </CardFooter>
    </Card>
  );
};
