'use client';

import { useMemo } from 'react';
import * as UAParser from 'ua-parser-js';

type IUseUAReturn = Omit<
  UAParser.IResult,
  'ua' | 'is' | 'withClientHints' | 'withFeatureCheck'
> & {
  isMobile: boolean;
  isAppleMobile: boolean;
  isStandalone: boolean;
};

const uaParser = new UAParser.UAParser();

export function useUserAgent() {
  return useMemo<IUseUAReturn | null>(() => {
    try {
      const uastring = window?.navigator?.userAgent;

      const device = uaParser.getDevice();
      const isMobile = device?.type === 'mobile';

      const isAppleMobile =
        device?.vendor === 'Apple' &&
        ['iPhone', 'iPod', 'iPad'].includes(device?.model ?? '');

      const isStandalone = window.matchMedia(
        '(display-mode: standalone)',
      ).matches;

      uaParser.setUA(uastring);
      return {
        os: uaParser.getOS(),
        browser: uaParser.getBrowser(),
        cpu: uaParser.getCPU(),
        device: uaParser.getDevice(),
        engine: uaParser.getEngine(),
        isMobile,
        isAppleMobile,
        isStandalone,
      };
    } catch (_err: any) {
      return null;
    }
  }, []);
}
