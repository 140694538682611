import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import Image, { type StaticImageData } from 'next/image';

import { cn } from '@/lib/utils';

import { CardContent, CardHeader } from '../ui/card';
import { BubbleCard } from './bubble-card';

export interface ILogoBubbleCardProps {
  className?: string;
  titleClassName?: string;
  id: string;
  title: string;
  description: string;
  image?: StaticImageData;
  imageAlt?: string;
  lottieSrc?: string;
}

export const LogoBubbleCard = ({
  className,
  titleClassName,
  id,
  title,
  description,
  image,
  imageAlt,
  lottieSrc,
}: ILogoBubbleCardProps) => {
  return (
    <BubbleCard
      className={cn(
        'min-h-[340px] size-full min-w-[310px] max-w-[400px]',
        className,
      )}
    >
      <div className="p-6">
        <CardHeader className="pb-0">
          <div className="flex flex-row items-center justify-between">
            <h4 className={cn('text-2xl font-medium', titleClassName)}>
              {title}
            </h4>
            <div className="size-16">
              {image && (
                <Image
                  className="size-16"
                  src={image}
                  alt={imageAlt ?? `logo for ${id} integration`}
                />
              )}
              {lottieSrc && (
                <div className="size-16">
                  <DotLottieReact src={lottieSrc} loop autoplay />
                </div>
              )}
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <div>
            <p className="pb-8 pt-4 text-lg text-muted-foreground">
              {description}
            </p>
          </div>
        </CardContent>
      </div>
    </BubbleCard>
  );
};
