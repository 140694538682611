'use client';

import { once } from 'events';
import type { Variants } from 'framer-motion';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

interface FadeInProps {
  children: React.ReactNode;
  delay?: number;
  duration?: number;
  threshold?: number;
  className?: string;
  inViewOptions?: {
    onlyStartInView?: boolean;
    once: boolean;
  };
}

const FadeIn: React.FC<FadeInProps> = ({
  children,
  delay = 0,
  duration = 0.5,
  threshold = 0.3,
  className = '',
  inViewOptions = {
    onlyStartInView: true,
    once: true,
  },
}) => {
  const controls = useAnimation();

  const [ref, inView] = useInView({
    triggerOnce: inViewOptions?.once,
    threshold,
  });

  useEffect(() => {
    if (inViewOptions.onlyStartInView) {
      if (inView) {
        controls.start('visible');
      } else if (!once) {
        controls.start('hidden');
      }
    } else {
      controls.start('visible');
    }
  }, [controls, inView, inViewOptions]);

  const variants: Variants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration,
        delay,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
      className={className}
    >
      {children}
    </motion.div>
  );
};

export default FadeIn;
