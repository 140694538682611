import { type PropsWithChildren } from 'react';

import { useBetterMediaQuery } from '@/hooks/use-better-media-query';
import { cn } from '@/lib/utils';

import { Button } from './ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from './ui/drawer';

export type IResponsiveDialogProps = PropsWithChildren & {
  title: string;
  className?: string;
  description?: React.ReactNode | string;
  triggerNode?: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  disableClose?: boolean;
};

export const ResponsiveDialog = ({
  title,
  className,
  description,
  triggerNode,
  children,
  open,
  onOpenChange,
  disableClose,
}: IResponsiveDialogProps) => {
  // sm
  // https://tailwindcss.com/docs/responsive-design
  const isDesktop = useBetterMediaQuery('(min-width: 640px)');

  if (isDesktop) {
    return (
      <Dialog open={open} onOpenChange={onOpenChange}>
        {triggerNode && <DialogTrigger asChild>{triggerNode}</DialogTrigger>}
        <DialogContent
          className={cn(className)}
          closeButtonDisabled={disableClose}
          onInteractOutside={(e) => {
            if (disableClose) {
              e.preventDefault();
            }
          }}
        >
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
            {description && (
              <DialogDescription>{description}</DialogDescription>
            )}
          </DialogHeader>
          {children}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={open} onOpenChange={onOpenChange} dismissible={!disableClose}>
      {triggerNode && <DialogTrigger asChild>{triggerNode}</DialogTrigger>}
      <DrawerContent>
        <DrawerHeader className="text-left">
          <DrawerTitle>{title}</DrawerTitle>
          {description && <DrawerDescription>{description}</DrawerDescription>}
        </DrawerHeader>
        <div className="px-4">{children}</div>
        <DrawerFooter className="mb-8 pt-2">
          <DrawerClose asChild>
            <Button disabled={disableClose} variant="outline">
              Cancel
            </Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
