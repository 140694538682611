"use client";

import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import React, { useCallback } from "react";

import { useRoutes } from "@/hooks/use-routes";

import FadeIn from "../animation/fade-in";
import { Button } from "../ui/button";

export const IntroducingLayers = () => {
  const routes = useRoutes();

  const handleLearnMoreClick = useCallback(() => {
    routes.goToLayerHelp();
  }, [routes]);

  return (
    <div className="container">
      <div className="max-w-[600px] xl:max-w-full mx-auto grid grid-cols-1 content-start justify-items-center gap-x-40 py-10 md:py-16 xl:grid-cols-[1fr_auto]">
        <div className="flex min-h-[300px] w-full flex-col justify-center xl:text-start">
          <h1 className="text-center xl:text-start text-pretty text-3xl md:text-6xl xl:text-7xl">
            Enhance your AI with Knowledge Layers
          </h1>
          <p className="text-lg md:text-xl text-muted-foreground pt-4 text-center xl:text-start">
            Unlock the power of Knowledge Layers, a new way to enhance your
            knowledge base. Add layers to access expertly curated data and
            resources tailored to your needs.
          </p>
          <div className="pt-12">
            <Button className="w-full xl:w-1/3" onClick={handleLearnMoreClick}>
              Learn More
            </Button>
          </div>
        </div>
        <FadeIn duration={3}>
          <div className="size-full min-h-[500px] max-w-[500px]">
            <DotLottieReact
              src="/assets/lotties/layers.lottie"
              loop
              autoplay
              speed={0.5}
            />
          </div>
        </FadeIn>
      </div>
    </div>
  );
};
