import Image from 'next/image';

import { Card } from '@/components/ui/card';
import Chat2 from '@/public/assets/images/chat2.png';
import Chat2Light from '@/public/assets/images/chat2light.png';
import Dashboard2 from '@/public/assets/images/dashboard2.png';
import Dashboard2Light from '@/public/assets/images/dashboard2light.png';

export const HeroCards = () => {
  return (
    <div className="pointer-events-none relative hidden h-[500px] w-[900px] select-none flex-row flex-wrap gap-8 lg:flex">
      <Card className="absolute top-0 flex flex-col items-center justify-center overflow-hidden rounded-xl shadow-xl shadow-black/30 dark:shadow-lg  dark:shadow-white/10">
        <div className="hidden dark:block">
          <Image
            src={Dashboard2}
            alt="image of a knowledge base dashboard"
            quality={100}
          />
        </div>

        <div className="dark:hidden">
          <Image
            src={Dashboard2Light}
            alt="image of a knowledge base dashboard"
            quality={100}
          />
        </div>
      </Card>

      <Card className="absolute right-[60px] top-[60px] w-[370px] overflow-hidden rounded-xl shadow-xl shadow-black/30 dark:shadow-lg dark:shadow-white/10">
        <div className="hidden dark:block">
          <Image src={Chat2} alt="image of the chat interface" quality={100} />
        </div>

        <div className="dark:hidden">
          <Image
            src={Chat2Light}
            alt="image of the chat interface"
            quality={100}
          />
        </div>
      </Card>
    </div>
  );
};
