'use client';

/* eslint-disable react/no-unused-prop-types */
import { useState } from 'react';

import {
  type ILogoBubbleCardProps,
  LogoBubbleCard,
} from '../containers/logo-bubble-card';
import TextWriter from '../text-writer';

const features: ILogoBubbleCardProps[] = [
  {
    id: 'file-support',
    title: 'Extensive File Support',
    description:
      'Upload and manage various file types including documents, slides, audio, and more, with instant searchability across formats.',
  },
  {
    id: 'audio-transcription',
    title: 'Powerful Audio Transcriptions',
    description: `Powered by Whisper, the world's leading transcription model, convert your audio files into precise, searchable text for easy access to insights.`,
    lottieSrc: '/assets/lotties/audio-wave.lottie',
  },
];

export const USE_CASES = [
  'Research',
  // 'Customer Support',
  'Summarizing',
  'Transcribing',
  'Solutions',
  'Meeting Notes',
  // 'websites',
];

export const UseCases = () => {
  const [fileFormatIndex, setFileFormatIndex] = useState(0);

  return (
    <section id="use-cases" className="container py-8 sm:pb-16 sm:pt-24">
      <div className="flex flex-col items-center text-center">
        <h2 className="text-3xl font-bold md:text-4xl ">
          Your{' '}
          <span className="bg-gradient-to-b from-primary/80 to-primary bg-clip-text text-transparent">
            Personalized AI for{' '}
          </span>
        </h2>
        <TextWriter
          className="min-h-[80px] bg-clip-text text-[42px] font-bold text-primary-dark dark:bg-gradient-to-r dark:from-[#24c7eb] dark:to-[#2463ebd7] dark:text-transparent sm:min-h-[120px] sm:text-[75px] lg:min-h-[180px] lg:text-[120px]"
          text={USE_CASES[fileFormatIndex] as string}
          delay={75}
          eraseDelay={40}
          eraseOnComplete
          onEraseComplete={() =>
            setFileFormatIndex((prev) => {
              let next = prev + 1;

              if (next >= USE_CASES.length) {
                next = 0;
              }

              return next;
            })
          }
          beginEraseDelay={2500}
        />
      </div>
      <p className="mx-auto mb-8 mt-4 text-center text-lg text-muted-foreground md:w-3/4 md:text-xl">
        From PDFs and Word documents to audio and slides, Truffler is equipped
        to handle it all.
      </p>

      <div className="mx-auto grid max-w-max grid-cols-1 place-items-center gap-4 md:grid-cols-2">
        {features.map((feature) => (
          <LogoBubbleCard
            key={feature.id}
            className="max-w-[500px]"
            titleClassName="text-3xl"
            {...feature}
          />
        ))}
      </div>
    </section>
  );
};
