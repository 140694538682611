'use client';

import type { Easing } from 'framer-motion';
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

import { cn } from '@/lib/utils';

interface TextWriterProps {
  text: string;
  delay?: number;
  eraseDelay?: number;
  className?: string;
  cursorClassName?: string;
  onEraseComplete?: () => void;
  eraseOnComplete?: boolean;
  beginEraseDelay?: number;
  loop?: boolean;
}

const TextWriter: React.FC<TextWriterProps> = ({
  text,
  delay = 50,
  eraseDelay = 25,
  className = '',
  cursorClassName,
  onEraseComplete,
  eraseOnComplete = false,
  beginEraseDelay = 2000,
  loop = false,
}) => {
  const [displayText, setDisplayText] = useState('');
  const textRef = useRef<HTMLSpanElement>(null);
  const stepEasing: Easing = (t) => Math.floor(t * 2) / 2;

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    let currentIndex = 0;

    const writeText = () => {
      if (currentIndex <= text.length) {
        setDisplayText(text.slice(0, currentIndex));
        currentIndex += 1;
        timeoutId = setTimeout(writeText, delay);
      } else if (eraseOnComplete || loop) {
        timeoutId = setTimeout(eraseText, beginEraseDelay);
      }
    };

    const eraseText = () => {
      if (currentIndex > 0) {
        currentIndex -= 1;
        setDisplayText(text.slice(0, currentIndex));
        timeoutId = setTimeout(eraseText, eraseDelay);

        if (currentIndex === 0) {
          onEraseComplete?.();
        }
      } else if (loop) {
        currentIndex = 0;
        timeoutId = setTimeout(writeText, delay);
      }
    };

    writeText();

    return () => clearTimeout(timeoutId);
  }, [
    text,
    delay,
    eraseDelay,
    eraseOnComplete,
    beginEraseDelay,
    loop,
    onEraseComplete,
  ]);

  return (
    <span className={`inline-flex items-center ${className}`}>
      <span ref={textRef}>{displayText}</span>
      <motion.span
        className={cn(
          'ml-2 inline-block h-[1em] w-2 border border-solid bg-foreground',
          cursorClassName,
        )}
        animate={{ opacity: [1, 0] }}
        transition={{
          duration: 0.8,
          repeat: Infinity,
          ease: stepEasing,
        }}
      />
    </span>
  );
};

export default TextWriter;
