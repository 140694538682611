import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/public/assets/images/dropbox-tile.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/public/assets/images/gdrive_logo.png");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/src/components/landing/hero.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/src/components/landing/introducing-layers.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/src/components/landing/use-cases.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src835818838/src/ludus-website-next/src/components/pricing/pricing.tsx")