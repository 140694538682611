'use client';

// import { useState } from 'react';

import { ROUTES } from '@/hooks/use-routes';

import { ClerkSignUpButton } from '../clerk-sign-up-button';
import { LinkButton } from '../link-button';
import { PWAInstallButton } from '../pwa-button/pwa-install-button';
import { HeroCards } from './hero-cards';

export const Hero = () => {
  return (
    <section className="container grid place-items-center gap-10 py-20 md:py-32 lg:grid-cols-2">
      <div className="space-y-6 text-center lg:text-start">
        <main className="text-5xl font-normal text-[#2f2e2e] dark:text-primary-foreground md:text-7xl">
          <h1 className="inline md:leading-[80px]">
            Your Personal AI Assistant, Powered by Truffler.
          </h1>
        </main>

        <p className="mx-auto text-lg text-muted-foreground sm:text-xl md:w-10/12 lg:mx-0">
          Personalized AI solutions to help you search, understand, and leverage
          your data effortlessly.
        </p>

        <div className="space-y-4 md:space-x-4">
          {/* <Button className="w-full md:w-1/3">Get Started for free</Button> */}
          <ClerkSignUpButton
            className="w-full md:w-1/3"
            variant="default"
            size="default"
            buttenText="Get started for free"
            signedInComponent={
              <LinkButton
                className="w-full md:w-1/3"
                variant="default"
                href={ROUTES.AUTH_HOME}
              >
                Go to dashboard
              </LinkButton>
            }
          />

          <PWAInstallButton variant="outline" className="w-full md:w-1/3" />
        </div>
      </div>

      {/* Hero cards sections */}
      <div className="z-10 place-self-start">
        <HeroCards />
      </div>

      {/* Shadow effect */}
      <div className="shadow" />
    </section>
  );
};
