import type { PropsWithChildren } from 'react';

import { cn } from '@/lib/utils';

export type IBubbleCardProps = PropsWithChildren & {
  className?: string;
  innerClassName?: string;
  innerGradient?: boolean;
};

export const BubbleCard = ({
  children,
  className,
  innerClassName,
  innerGradient,
}: IBubbleCardProps) => {
  return (
    <div
      className={cn(
        'relative overflow-hidden rounded-3xl bg-transparent border-primary/25 border',
        className,
      )}
    >
      <div
        className={cn(
          'size-full inset-0 rounded-3xl bg-gradient-to-br dark:shadow-[inset_0_0_40px_rgba(59,130,246,0.2)]',
          innerGradient &&
            'dark:from-[rgba(59,130,246,0.2)] dark:to-[rgba(59,130,246,0)]',
          innerClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
};
