'use client';

import { Share, SquarePlus } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';

import { useUserAgent } from '@/hooks/use-user-agent';

import { IconButton, type IIconButtonProps } from '../icon-button';
import { ResponsiveDialog } from '../responsive-dialog';

type IPWAInstallButtonProps = Omit<IIconButtonProps, 'onClick'>;

export const PWAInstallButton = (props: IPWAInstallButtonProps) => {
  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);

  const [isIOSDialogOpen, setIsIOSDialogOpen] = useState(false);

  // to avoid hydration errors
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e as BeforeInstallPromptEvent);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () =>
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt,
      );
  }, []);

  const userAgent = useUserAgent();

  const handleInstallClick = useCallback(async () => {
    if (userAgent?.isAppleMobile) {
      setIsIOSDialogOpen(true);
    } else if (deferredPrompt) {
      await deferredPrompt.prompt();
    }
  }, [userAgent, deferredPrompt]);

  if (
    !mounted ||
    (!deferredPrompt && !userAgent?.isAppleMobile) ||
    userAgent?.isStandalone
  ) {
    return <div className="hidden" />;
  }

  return (
    <>
      <ResponsiveDialog
        title="Add to Home Screen"
        open={isIOSDialogOpen}
        onOpenChange={setIsIOSDialogOpen}
      >
        <p>
          To install this app on your iOS device, tap the share button
          <Share className="mx-1.5 inline size-5" />
          {`and then "Add to Home Screen"`}
          <SquarePlus className="mx-1.5 inline size-5" />.
        </p>
      </ResponsiveDialog>
      <IconButton onClick={handleInstallClick} {...props}>
        {userAgent?.isMobile ? `Add to Home Screen` : 'Add to Desktop'}
      </IconButton>
    </>
  );
};
